
.react-number-formatter-form{
  display: flex;
}

.react-number-formatter-input {
    border: 1px solid rgb(214, 214, 214);
    outline: none;
    margin-left: -1px;
    font-size: inherit;
    padding: 9px;
    background: none;
    width: 100%;
}

.react-number-formatter-dropbtn {
  border: 1px solid rgb(209, 207, 207);
  outline: none;
  font-weight: 700;
  color: #5a5959;
  background-color: rgb(248, 248, 248);
  font-size: inherit;
  padding: 9px 4px 9px 8px;
  cursor:pointer;
}

.react-number-formatter-dropbtn:hover{
  background: none;
  border: 1px solid rgb(224, 222, 222);
}

.react-number-formatter-dropbtn-text {
  display: flex;
}
.react-number-formatter-dropbtn-text {
  pointer-events: none;
}
.react-number-formatter-dropbtn-text span {
  margin: 1px 2px 0 0;
}
.react-number-formatter-dropbtn-text .react-number-formatter-arrow {
  font-size: 14px;
}


.react-number-formatter-dropbtn-false {
  border: 1px solid rgb(214, 214, 214);
  outline: none;
  font-weight: 500;
  color: #5a5959;
  background-color: rgb(248, 248, 248);
  font-size: 14px;
  padding: 10.5px;
  pointer-events: none;
}

.react-number-formatter-dropdown-parent {
  position: relative;
  display: inline-block;
}

.react-number-formatter-dropdown-content {
  display: none;
  position: absolute;
  background-color: #eeeeee;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 5;
  height:500px;
  overflow: scroll;
  overflow-x: auto;
}

.react-number-formatter-dropdown-content .react-number-formatter-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 13px;
  border-radius: 0%;
  background: none;
  border: none;
  text-align: left;
}

.react-number-formatter-dropdown-content .react-number-formatter-option:hover {background-color: rgb(224, 222, 222);}

.show {display:block;}

